<template>
	<div class="cassie-vertical-md">
		<FilterBar
			:search-query.sync="privacyPolicySearchQuery"
			search-query-label="Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changePrivacyPolicySearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
		>
			<template #action>
				<PrimaryActionButton
					v-if="!readOnlyPermissions"
					@click="onCreateClick"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Privacy Policies
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredPrivacyPolicies"
					sort-by="privacyPolicyId"
					sort-desc
					@click:row="onEditPrivacyPolicy"
				>
					<template #item.isGlobalDefault="{ item }">
						<v-icon
							v-if="item.isGlobalDefault"
							small
							class="mr-2"
						>
							mdi-check
						</v-icon>
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Open Privacy Policy"
							@click="onEditPrivacyPolicy(item)"
						>
							{{ readOnlyPermissions ? 'mdi-eye' : 'mdi-pencil' }}
						</IconButton>
						<IconButton
							v-if="!readOnlyPermissions"
							tooltip-text="Delete Privacy Policy"
							@click.stop.prevent="onRemovePrivacyPolicy(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<Modal
			v-if="privacyPolicyToBeRemoved"
			width="500px"
		>
			<template #modal-title>
				Delete {{ privacyPolicyToBeRemoved.text }}
			</template>
			<template #modal-content>
				Are you sure you wish to delete this Privacy Policy? <br>
				You will not be able to recover it if you confirm its deletion.
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="closeDeletePrivacyPolicyModal">
					Close
				</SecondaryActionButton>
				<DeleteButton @click="deletePrivacyPolicy">
					Delete
				</DeleteButton>
			</template>
		</Modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import Modal from '../../../../../shared/components/modal.vue'
import DeleteButton from '../../../../../shared/components/delete-button.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { brandOptions, changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { changePrivacyPolicySearchQuery, privacyPolicySearchQuery } from '../../../../../shared/state/privacy-policies.js'
import { MANAGE_PRIVACY_POLICY } from '../../../router/route-names.js'
import { deletePrivacyPolicy } from '../../../../../shared/utils/api/privacy-policies.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		FilterBar,
		SecondaryActionButton,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		Modal,
		DeleteButton,
		IconButton
	},
	props: {
		privacyPolicies: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			brandOptions,
			changePrivacyPolicySearchQuery,
			privacyPolicySearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			tableHeaders: [
				{ text: 'ID', value: 'privacyPolicyId', width: '10%' },
				{ text: useLabels('Brand'), value: 'brandName', width: '15%' },
				{ text: 'Name', value: 'privacyPolicyName', width: '20%' },
				{ text: 'Active Version ID', value: 'activeVersionId', width: '10%' },
				{ text: 'External Version Ref', value: 'externalVersionRef', width: '15%' },
				{ text: 'Global Default?', value: 'isGlobalDefault', width: '10%' },
				{ text: 'Created Date', value: 'createdDate', width: '10%' },
				{ text: 'Action', value: 'action', width: '10%', sortable: false }
			],
			privacyPolicyToBeRemoved: null
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		filteredPrivacyPolicies () {
			return this.privacyPolicies.filter(({ text, brandId }) => {
				let check = true
				if (privacyPolicySearchQuery.value) check = text.toLowerCase().includes(privacyPolicySearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && (selectedAdminPortalBrandId.value === brandId)
				return check
			})
		},
		readOnlyPermissions () {
			return !this.checkPermission(PRIVACY_POLICIES_MODULE_FULL_PERMISSIONS)
		},
		brandLabel () {
			return `${useLabels('Brand')}`
		}
	},
	methods: {
		onCreateClick () {
			this.$router.push({ name: MANAGE_PRIVACY_POLICY })
		},
		onEditPrivacyPolicy (privacyPolicyToEdit) {
			this.$router.push({ name: MANAGE_PRIVACY_POLICY, params: { privacyPolicyToEdit } })
		},
		onRemovePrivacyPolicy (privacyPolicy) {
			this.privacyPolicyToBeRemoved = privacyPolicy
		},
		closeDeletePrivacyPolicyModal () {
			this.privacyPolicyToBeRemoved = null
		},
		async deletePrivacyPolicy () {
			await deletePrivacyPolicy(this.privacyPolicyToBeRemoved.privacyPolicyId)
			showSnackbar('This privacy policy has been removed')
			this.closeDeletePrivacyPolicyModal()
			this.$emit('load-privacy-policies')
		}
	}
}
</script>
