<template>
	<PrivacyPoliciesLayout>
		<template #content>
			<PrivacyPoliciesTab
				:privacy-policies="privacyPolicies"
				@load-privacy-policies="loadPrivacyPolicies"
			/>
		</template>
	</PrivacyPoliciesLayout>
</template>

<script>
import { ref } from '@vue/composition-api'
import PrivacyPoliciesTab from './privacy-policies-tab.vue'
import PrivacyPoliciesLayout from './privacy-policies-layout.vue'
import { getPrivacyPolicies, getActivePrivacyPolicies } from '../../../../../shared/utils/api/privacy-policies.js'

export const tab = ref('0')

export default {
	components: { PrivacyPoliciesLayout, PrivacyPoliciesTab },
	setup () {
		return {
			tab,
			getPrivacyPolicies,
			getActivePrivacyPolicies
		}
	},
	data () {
		return {
			tabs: [{ title: 'Privacy Policies', slot: '0' }, { title: 'Auto-Allocation', slot: '1' }],
			privacyPolicies: [],
			autoAllocations: []
		}
	},
	created () {
		this.loadPrivacyPolicies()
	},
	methods: {
		async loadPrivacyPolicies () {
			// load auto allocations here
			const { data: privacyPolicies } = await getActivePrivacyPolicies()
			this.privacyPolicies = privacyPolicies
		}
	}
}
</script>
